import { Injectable } from '@angular/core';
import { map, first, take } from 'rxjs/operators';
import { throwError as _throw, of, } from 'rxjs';
import { Seller } from '../models/seller';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AuthenticationService } from './authentication.service';
import { User } from '../models/user';
import { systemSettings } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SellerService {

    objs: AngularFirestoreCollection<Seller>;
    obj: AngularFirestoreDocument<Seller>;
    selectedObj: Seller;
    private user: User;

    constructor(
        private firestore: AngularFirestore,
        private authService: AuthenticationService,
    ) { }

    getSellerUid(email: string): Promise<any> {
        this.objs = this.firestore.collection(`${systemSettings.collectionPrefix}_sellers`, ref => ref.where('email', '==', email));
        return this.objs.snapshotChanges().pipe(take(1)).pipe(map(changes => changes
            .map(c => ({
                uid: c.payload.doc.data().uid,
            })))).pipe(first()).toPromise();
    }

    setSelected(o: Seller) {
        this.selectedObj = o;
    }

    getSelected() {
        return of(this.selectedObj);
    }

    getSeller(uid: String): Promise<any> {
        return this.firestore.collection(`${systemSettings.collectionPrefix}_sellers`, ref => ref.where('uid', '==', uid))
            .snapshotChanges().pipe(first()).toPromise();
    }

    find(uid: String) {
        this.user = this.authService.getCurrentUser().getValue();
        if (uid == '') {
            uid = this.user.uid;
        }
        this.objs = this.firestore.collection(`${systemSettings.collectionPrefix}_sellers`, ref => ref.where('uid', '==', uid));
        return this.objs.snapshotChanges().pipe(map(changes => changes
            .map(c => ({
                id: c.payload.doc.id,
                shopname: c.payload.doc.data().shopname,
                firstname: c.payload.doc.data().firstname,
                lastname: c.payload.doc.data().lastname,
                city: c.payload.doc.data().city,
                district: c.payload.doc.data().district,
                street: c.payload.doc.data().street,
                house: c.payload.doc.data().house,
                zipcode: c.payload.doc.data().zipcode,
                country: c.payload.doc.data().country,
                telefon: c.payload.doc.data().telefon,
                email: c.payload.doc.data().email,
                web: c.payload.doc.data().web,
                description: c.payload.doc.data().description,
                keywords: c.payload.doc.data().keywords,
                option: c.payload.doc.data().option,
                img: c.payload.doc.data().img,
                companyform: c.payload.doc.data().companyform,
                taxnumber: c.payload.doc.data().taxnumber,
                imprint: c.payload.doc.data().imprint,
                infos: c.payload.doc.data().infos,
                deliveryAddress: c.payload.doc.data().deliveryAddress,
                timestamp: c.payload.doc.data().timestamp,
                uid: c.payload.doc.data().uid,
            }))));
    }

    save(data: any) {
        this.user = this.authService.getCurrentUser().getValue();
        if (data.uid == '') {
            data.uid = this.user.uid;
        }
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_sellers`)
            .doc(data.id)
            .set(data);
    };

    add(data: any) {
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_sellers`)
            .add(data);
    };

    delete(data: any) {
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_sellers`)
            .doc(data.id)
            .delete();
    }

    sendmail(data: any) {
        this.firestore
            .collection("trigger_emails")
            .add(
                {
                    to: 'pohlcuong@gmail.com',
                        message: {
                            subject: `Kundeinfo für NGOAN Streetfood`,
                            html: 
                            `<p>${data}</p>` +
                            '<p>--</p>' +
                            '<p><small>Diese E-Mail enthält vertrauliche und/oder rechtlich geschützte Informationen. Wenn Sie nicht der richtige Adressat sind, oder diese E-Mail irrtümlich erhalten haben, informieren Sie bitte den Absender und löschen Sie diese Mail. Das unerlaubte Kopieren sowie die unbefugte Weitergabe dieser E-Mail und der darin enthaltenen Informationen sind nicht gestattet.</small></p>'
                        },
                }
            );
    };
}