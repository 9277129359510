<div *ngIf="fragment" id="carousel-show-1z" class="carousel slide carousel-fade pt-4" data-ride="carousel">
    <div class="carousel-inner" role="listbox">
        <div class="carousel-item active">
            <div class="view" style="background-image: url('../../assets/img/pageimg/{{fragment}}.jpg'); background-repeat: no-repeat; background-size: cover;">
                <div class="mask rgba-black-strong d-flex justify-content-center align-items-center">
                    <div class="text-center white-text mx-5 wow fadeIn">
                        <img *ngIf="sellerAdmin" src="{{sellerAdmin.img[1]}}" class="img-profile-150 mt-5" alt="{{sellerAdmin.shopname}}">
                        <mat-spinner *ngIf="!sellerAdmin" class="img-profile-150 mt-5"></mat-spinner>
                        <h3 class="mt-3">
                            <strong>Authentische Straßenküche Vietnams</strong>
                        </h3>
                        <p class="mt-3">
                            <a href="https://onlinebestellung.ngoan-streetfood.de/shop/reserve/97084/W%C3%BCrzburg/NGOAN%20Streetfood/lz9PD8X7ZoSzp7a4WaM96jgxB3g1" target="_blank"><button mat-raised-button color="primary"><mat-icon>today</mat-icon> Jetzt Reservieren &nbsp;</button></a>
                        </p>
                        <p>
                            <a href="https://onlinebestellung.ngoan-streetfood.de/shop/article/97084/W%C3%BCrzburg/NGOAN%20Streetfood/lz9PD8X7ZoSzp7a4WaM96jgxB3g1" target="_blank"><button mat-raised-button color="primary"><mat-icon class="pulse">alarm</mat-icon> Jetzt Vorbestellen</button></a>
                        </p>
                        <p>
                            <a href="tel:093168084329" target="_blank"><button mat-raised-button color="primary"><mat-icon class="wiggle">phone</mat-icon> (0931) 6808 4329 &nbsp;</button></a><br>
                            <small><b>Mo. - Fr. von 11:00 - 20:30 Uhr</b></small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!fragment" class="pt-5">&nbsp;</div>
<main>
    <div class="container">
        <section *ngIf="articles && searchkey === 'vietnam-streetfood'">
            <h1 class="my-4 h1 text-center grey-text" *ngFor="let article of getGroupArticles('sitemap-' + searchkey)"><img class="mb-2" style="border-radius: 50%;" src="assets/img/pageimg/ngoan.png" width="40" height="40" /> {{article.articlename}}</h1>
            <p class="text-center mb-5">
                <a href="{{'https://speisekarte.ngoan-streetfood.de/NGOAN_Streetfood_Speisekarte.pdf?q=' + (currentDate | date:'ddMMyyyy' )}}" download target="_blank" title="NGOAN Speisekarte">
                    <button mat-raised-button><mat-icon class="wiggle">cloud_download</mat-icon> Speisekarte Download</button>
                </a>
            </p>

            <div class="row">
                <div class="col-lg-4 mb-3 text-center" *ngFor="let article of getGroupArticles('Spezial der Woche')">
                    <div class="card h-100">
                        <div class="card-body">
                            <h4 class="text-muted"><strong>{{article.articlename}}</strong></h4>
                            <img src="{{article.img[1]}}" class="mx-auto img-profile-300 mb-2" alt="{{article.articlename}}">
                            <div class="my-1 text-left"><small class="text-muted" [innerHTML]="article.description"></small></div>
                            <div class="my-1" *ngFor="let _opt of article.options">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <small class="text-muted my-0">{{_opt.nr}}.&nbsp;{{_opt.description}}</small>
                                    </div>
                                    <div>
                                        <small class="text-muted"><nobr>{{_opt.price}}</nobr></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mb-3 text-center" *ngFor="let article of getGroupArticles('Speisekarte 3')">
                    <div class="card h-100">
                        <div class="card-body">
                            <h4 class="text-muted"><strong>{{article.articlename}}</strong></h4>
                            <img src="{{article.img[1]}}" class="mx-auto img-profile-300 mb-2" alt="{{article.articlename}}">
                            <div class="my-1 text-left"><small class="text-muted" [innerHTML]="article.description"></small></div>
                            <div class="my-1" *ngFor="let _opt of article.options">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <small class="text-muted my-0">{{_opt.nr}}.&nbsp;{{_opt.description}}</small>
                                    </div>
                                    <div>
                                        <small class="text-muted"><nobr>{{_opt.price}}</nobr></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 mb-3 text-center" *ngFor="let article of getGroupArticles('Speisekarte 1')">
                    <div class="card h-100">
                        <div class="card-body">
                            <h4 class="text-muted"><strong>{{article.articlename}}</strong></h4>
                            <img src="{{article.img[1]}}" class="mx-auto img-profile-300 mb-2" alt="{{article.articlename}}">
                            <div class="my-1 text-left"><small class="text-muted" [innerHTML]="article.description"></small></div>
                            <div class="my-1" *ngFor="let _opt of article.options">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <small class="text-muted my-0">{{_opt.nr}}.&nbsp;{{_opt.description}}</small>
                                    </div>
                                    <div>
                                        <small class="text-muted"><nobr>{{_opt.price}}</nobr></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 mb-3 text-center" *ngFor="let article of getGroupArticles('Speisekarte 2')">
                    <div class="card h-100">
                        <div class="card-body">
                            <h4 class="text-muted"><strong>{{article.articlename}}</strong></h4>
                            <img src="{{article.img[1]}}" class="mx-auto img-profile-300 mb-2" alt="{{article.articlename}}">
                            <div class="my-1 text-left"><small class="text-muted" [innerHTML]="article.description"></small></div>
                            <div class="my-1" *ngFor="let _opt of article.options">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <small class="text-muted my-0">{{_opt.nr}}.&nbsp;{{_opt.description}}</small>
                                    </div>
                                    <div>
                                        <small class="text-muted"><nobr>{{_opt.price}}</nobr></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="my-3">
        </section>

        <section *ngIf="articles && searchkey === 'vietnam-streetfood'">
            <h2 class="my-4 h2 text-center grey-text"><img class="mb-2" style="border-radius: 50%;" src="assets/img/pageimg/ngoan.png" width="40" height="40" /> Drinks</h2>
            <p class="my-4 text-center grey-text">Original Kaffee, Tee und die berühmtesten Biere aus Vietnam ..</p>
            <div class="row">
                <div class="col-lg-4 mb-3 text-center" *ngFor="let article of getGroupArticles('Drinks 1')">
                    <div class="card h-100">
                        <div class="card-body">
                            <h4 class="text-muted"><strong>{{article.articlename}}</strong></h4>
                            <img src="{{article.img[1]}}" class="mx-auto img-profile-300 mb-2" alt="{{article.articlename}}">
                            <div class="my-1 text-left"><small class="text-muted" [innerHTML]="article.description"></small></div>
                            <div class="my-1" *ngFor="let _opt of article.options">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <small class="text-muted my-0">{{_opt.nr}}.&nbsp;{{_opt.description}}</small>
                                    </div>
                                    <div>
                                        <small class="text-muted"><nobr>{{_opt.price}}</nobr></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p class="my-4 text-center grey-text"><b>Probierenswert! Original vietnamesischer Kaffee. Wie in Vietnam mit traditioneller Zubereitung im Tassenfilter. Der Kaffee schmeckt kräftig, schokoladig und am besten mit gesüßter Kondesmilch.</b>
            </p>
            <hr class="my-3">
        </section>

        <section *ngIf="articles && searchkey === 'vegetarische-speisen'">
            <h1 class="my-4 h1 text-center grey-text" *ngFor="let article of getGroupArticles('sitemap-' + searchkey)"><img class="mb-2" style="border-radius: 50%;" src="assets/img/pageimg/ngoan.png" width="40" height="40" /> {{article.articlename}}</h1>

            <div class="card h-100 mt-4 mb-3 fakelink text-center" *ngFor="let article of getGroupArticles('Vegetarisch Top')">
                <div class="card-body">
                    <div class="row text-muted">
                        <div class="col-lg-4">
                            <div><img class="img-profile-300 pulse" src="{{article.img[1]}}" alt="{{article.articlename}}"></div>
                        </div>
                        <div class="col-lg-8 text-left">
                            <h4 class="text-center"><strong>{{article.articlename}}</strong></h4>
                            <div class="my-1 text-left"><span class="text-muted" [innerHTML]="article.description"></span></div>
                            <div class="my-1" *ngFor="let _opt of article.options">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <small class="text-muted my-0">{{_opt.nr}}.&nbsp;{{_opt.description}}</small>
                                    </div>
                                    <div>
                                        <small class="text-muted"><nobr>{{_opt.price}}</nobr></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 mb-3 text-center" *ngFor="let article of getGroupArticles('Vegetarisch 1')">
                    <div class="card h-100">
                        <div class="card-body">
                            <h4 class="text-muted"><strong>{{article.articlename}}</strong></h4>
                            <img src="{{article.img[1]}}" class="mx-auto img-profile-300 mb-2" alt="{{article.articlename}}">
                            <div class="my-1 text-left"><small class="text-muted" [innerHTML]="article.description"></small></div>
                            <div class="my-1" *ngFor="let _opt of article.options">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <small class="text-muted my-0">{{_opt.nr}}.&nbsp;{{_opt.description}}</small>
                                    </div>
                                    <div>
                                        <small class="text-muted"><nobr>{{_opt.price}}</nobr></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 mb-3 text-center" *ngFor="let article of getGroupArticles('Vegetarisch 2')">
                    <div class="card h-100">
                        <div class="card-body">
                            <h4 class="text-muted"><strong>{{article.articlename}}</strong></h4>
                            <img src="{{article.img[1]}}" class="mx-auto img-profile-300 mb-2" alt="{{article.articlename}}">
                            <div class="my-1 text-left"><small class="text-muted" [innerHTML]="article.description"></small></div>
                            <div class="my-1" *ngFor="let _opt of article.options">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <small class="text-muted my-0">{{_opt.nr}}.&nbsp;{{_opt.description}}</small>
                                    </div>
                                    <div>
                                        <small class="text-muted"><nobr>{{_opt.price}}</nobr></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="my-3">
        </section>

        <section *ngIf="articles && searchkey === 'banhmi'">
            <h1 class="my-4 h1 text-center grey-text" *ngFor="let article of getGroupArticles('sitemap-' + searchkey)"><img class="mb-2" style="border-radius: 50%;" src="assets/img/pageimg/ngoan.png" width="40" height="40" /> {{article.articlename}}</h1>
            <div class="row" *ngFor="let article of getGroupArticles('Banhmi 1')">
                <div class="col-lg-6 mb-3 text-center">
                    <img src="../../assets/img/pageimg/banhmi.jpg" class="img-responsive-x" alt="NGOAN-Banhmi">
                </div>
                <div class="col-lg-6 mb-3 text-left">
                    <h4 class="text-muted"><strong>{{article.articlename}}</strong></h4>
                    <div class="text-muted mb-2" [innerHTML]="article.description"></div>
                </div>
            </div>
            <hr class="my-3">
        </section>

        <section *ngIf="articles && searchkey === 'fischsauce'">
            <h1 class="my-4 h1 text-center grey-text" *ngFor="let article of getGroupArticles('sitemap-' + searchkey)"><img class="mb-2" style="border-radius: 50%;" src="assets/img/pageimg/ngoan.png" width="40" height="40" /> {{article.articlename}}</h1>
            <div class="row" *ngFor="let article of getGroupArticles('Fischsauce 1')">
                <div class="col-lg-6 mb-3 text-center">
                    <img src="../../assets/img/pageimg/fischsauce.jpg" class="img-responsive-x" alt="NGOAN-Fischsauce">
                </div>
                <div class="col-lg-6 mb-3 text-left">
                    <h4 class="text-muted"><strong>{{article.articlename}}</strong></h4>
                    <div class="text-muted mb-2" [innerHTML]="article.description"></div>
                </div>
            </div>
            <hr class="my-3">
        </section>

        <section *ngIf="articles && searchkey === 'geschenkgutschein'">
            <h1 class="my-4 h1 text-center grey-text" *ngFor="let article of getGroupArticles('sitemap-' + searchkey)"><img class="mb-2" style="border-radius: 50%;" src="assets/img/pageimg/ngoan.png" width="40" height="40" /> {{article.articlename}}</h1>
            <div class="row" *ngFor="let article of getGroupArticles('Geschenkgutschein 1')">
                <div class="col-lg-6 mb-3 text-center">
                    <img src="../../assets/img/pageimg/ngoan_geschenkgutschein.jpg" class="img-responsive-x" alt="Geschenkgutschein">
                </div>
                <div class="col-lg-6 mb-3 text-left">
                    <h4 class="text-muted"><strong>{{article.articlename}}</strong></h4>
                    <div class="text-muted mb-2" [innerHTML]="article.description"></div>
                </div>
            </div>
            <hr class="my-3">
        </section>

        <section *ngIf="articles && searchkey === 'ueberuns'">
            <h1 class="my-4 h1 text-center grey-text" *ngFor="let article of getGroupArticles('sitemap-' + searchkey)"><img class="mb-2" style="border-radius: 50%;" src="assets/img/pageimg/ngoan.png" width="40" height="40" /> {{article.articlename}}</h1>
            <div class="row" *ngFor="let article of getGroupArticles('Über NGOAN')">
                <div class="col-lg-12 mb-3 text-left text-muted">
                    <h4><strong>{{article.articlename}}</strong></h4>
                    <div class="mb-2" [innerHTML]="article.description"></div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 mb-3 text-left text-muted">
                    <h4><strong>Bei NGOAN - da schmeckt man vietnamesisch!</strong></h4>
                    <p>Das Restaurant von Ngoan ist ein Refugium für alle, die die vietnamesische Küche lieben. Hier kann man die echte vietnamesische Küche genießen, die von Ngoan nach traditionellen Originalrezepten zubereitet wird.</p>
                    <p><strong>Es ist ein kulinarisches Abenteuer, das man auf keinen Fall verpassen sollte!</strong></p>
                    <p>Besuchen Sie unser Restaurant-Blog und tauchen Sie ein in die Welt des guten Essens! Hier finden Sie spannende Beiträge rund um unsere Küche, unsere Spezialitäten und unsere Leidenschaft für gutes Essen. </p>
                    <div class="text-center"><a href="https://blog.ngoan-streetfood.de/" target="_blank"><button mat-raised-button><img src="https://onlinebestellung.ngoan-streetfood.de/assets/img/socialmedia/blogger.png" width="18px" height="18px"> Erfahren Sie mehr auf unserem Blog</button></a></div>    
                </div>
                <div class="col-lg-6 mb-3 text-center">
                    <iframe width="99%" height="315" src="https://www.youtube.com/embed/9jQlxedps5c" title="NGOAN Streetfood" frameborder="0"></iframe>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <hr class="my-2">
                    <div class="wow fadeIn text-muted py-1">
                        <div><img class="img-profile mr-1" src="../../assets/img/pageimg/ngoan.png"> <b> <strong>NGOAN Streetfood</strong> </b>
                        </div>
                        <div *ngIf="articles">
                            <div *ngFor="let article of getGroupArticles('rezension')">
                                <p>{{article.img_top_description}} <a href="https://g.page/NGOAN-Streetfood" target="_blank">{{article.img_bottom_description}}</a></p>
                                <h4 class="mb-3"><strong>Das sagen Kunden über NGOAN Streetfood</strong></h4>
                                <div [innerHTML]="article.description"></div>
                            </div>
                        </div>
                    </div>
                    <div class="wow fadeIn text-muted py-1">
                        <p><a href="https://g.page/NGOAN-Streetfood" target="_blank">Mehr dazu ... </a></p>
                    </div>
                    <hr class="my-1">
                </div>
            </div>
        </section>

        <section *ngIf="articles && searchkey === 'karriere'">
            <h1 class="my-4 h1 text-center grey-text" *ngFor="let article of getGroupArticles('sitemap-' + searchkey)">{{article.articlename}}</h1>
            <div class="row" *ngFor="let article of getGroupArticles('Karriere')">
                <div class="col-lg-12 mb-3 text-left">
                    <h4 class="text-muted"><strong>{{article.articlename}}</strong></h4>
                    <div class="text-muted mb-2" [innerHTML]="article.description"></div>
                </div>
            </div>
            <hr class="my-3">
        </section>

        <section *ngIf="articles && searchkey === 'datenschutz'">
            <h1 class="my-4 h1 text-center grey-text" *ngFor="let article of getGroupArticles('sitemap-' + searchkey)">{{article.articlename}}</h1>
            <div class="row" *ngFor="let article of getGroupArticles('Datenschutz')">
                <div class="col-lg-12 mb-3 text-left">
                    <h4 class="text-muted"><strong>{{article.articlename}}</strong></h4>
                    <div class="text-muted mb-2" [innerHTML]="article.description"></div>
                </div>
            </div>
            <hr class="my-3">
        </section>

        <section *ngIf="sellerAdmin && searchkey === 'impressum'">
            <h1 class="my-4 h1 text-center grey-text" *ngFor="let article of getGroupArticles('sitemap-' + searchkey)">{{article.articlename}}</h1>
            <div class="row">
                <div class="col-lg-12 mb-3 text-left">
                    <div class="text-muted mb-2" [innerHTML]="sellerAdmin.imprint"></div>
                </div>
            </div>
            <hr class="my-3">
        </section>

    </div>
</main>